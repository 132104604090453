.container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 18px 0px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: black;
}

.container > label > svg {
}