.button {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  margin: 6px 0 ;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

}

.button:disabled{
  box-shadow: none;
}

.button > svg + span {
  margin-left: 10px;
}

.button > span + svg {
  margin-left: 10px;
}

/* styleType Colors */

.primary {
  background: #FF995B;
  color: #FFFFFF;
}

.primary:hover:enabled {
  background: #FF7C1B;
}

.primary:disabled{
  background-color: #E7E7E7;
}

.secondary {
  background: white;
  color: #FF995B;
  border: 1px solid #FF995B;
}

.secondary:hover:enabled {
  border-color: #FF7C1B;
  color: #FF7C1B;
}

.secondary:disabled{
  border-color: #E7E7E7;
  color: #E7E7E7;
}
