.container {
  box-sizing: border-box;
  height: 415px;
  width: 100%;

  padding: 20px 80px;

  overflow: auto;
}

.row {
  display: flex;
  margin: 12px 0;
}

.dobleRow{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: solid lightgray ;
}

.rowButtons {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.row > * + * {
  margin-left: 32px;
}

.eraseButton {
  padding: 12px 0 0 0;
  background: none;
  border: none;
  color: #9B9B99 ;
}

.eraseButton:hover {
  color: #FF995B;
}

.newOperationContainer > * + * {
  margin: 23px 0;
}