.container {
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: stretch;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.tierContainer{
  padding: 17px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: #333333;
}

.foldedTable {
  width: 100%;
  border-collapse: separate;
}


.buttonsContainer {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: left;

  margin: 30px 0;
}

.buttonsContainer > * + * {
  margin-left: 16px;
}

.tierContainer > * + * {
  margin: 0 57px;
}

.foldedTable thead tr {
  box-shadow: 0px 1px 0px rgba(155, 155, 153, 0.25),
              0px -1px 0px rgba(155, 155, 153, 0.25);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  
  color: #333333;
}

.foldedTable tbody tr {
  box-shadow: 0px 1px 0px rgba(155, 155, 153, 0.25);
}

.foldedTable thead td {
  padding: 19px 30px;
}

.foldedTable td {
  text-align: center;

}

.businessRow {
  height: 56px;
}

.operationRow {
  height: 30px;
  transition: height 50ms; 
  overflow: hidden;
  color: #9B9B99;
}

.rowShowing {
  height: 30px;
}
.rowHidden {
  display: none;
}

.phantomCell {
  width: 100%;
}

.businessName {
  display: flex;
  align-items: center;
  justify-content: left;
}

.inputOperValue,
.inputValue {
  outline: none;
  border: none;

  text-align: center;
}

.inputOperValue{
  font-size: inherit;
  color: inherit;
}


.inputValue::-webkit-outer-spin-button,
.inputValue::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.inputValue[type=number]{
  -moz-appearance: textfield;
}

.userCount,
.origin{
  color: #9B9B99;
}

.operName {
  color: red;
  text-align: left;
}