.container {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: none;

  margin: 40px 0;

  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;

  color: #ff995b;
}

.content {
  display: flex;

  flex: 1 1 auto;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
}

.rightSideContainer {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;

  padding: 0 52px;
}

.businessList {
  flex: 1 0 auto;
}

.businessColumn {
  text-align: left;
}
