.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.3);
}

.window {
  height: 348px;
  width: 770px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: white;
}

.header {
  height: 82px;
  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 4px solid #66BB6A;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.headerSuccess {
  color: #66BB6A;;
  border-color: currentColor;
}

.headerError {
  color: #D33604;
  border-color: currentColor;
}

.headerNeutral {
  color: #FF995B;
  border-color: currentColor;
}

.body {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
}

.header > svg {
  margin: 0 10px;
}