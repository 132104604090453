.container {
  display: flex;
  align-items: center;
  justify-content: center;

}

.switchBackground {
  position: absolute;
  top: 6px;
  right: -10px;

  height: 10px;
  width: 20px;
  background: #FF995B;
  border-radius: 10px;
}

.label {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  text-transform: uppercase;
  color: #9B9B99;

}

.labelRight {
  padding: 4px 0 4px 20px;
}

.labelLeft {
  padding: 4px 20px 4px 0;
}

.ball {
  position: absolute;
  left: 2px;
  top: 2px;
  height: 6px;
  width:  6px;
  border-radius: 3px;

  background: white;

  transition: transform 0.5s ease;
}

.ballTrue {
  transform: translate(10px)
}

.ballFalse {
  transform: translate(0)
}

.labelOn {
  color: #FF995B;
}

.labelOff {

}