.container {
  display: flex;
  align-items: center;

  margin: 36px 0px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: black;
}

.container > svg {
  margin-right: 10px;

  color: #ff7c1b;
}

.tierList {
  flex: 1 0 auto;

  padding: 0 60px;
  margin-bottom: 30px;

  border-right: 1px solid #9b9b99;
}

.tierListTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #333333;
}
