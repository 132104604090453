.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background: #333333;
  opacity: 0.6;
  z-index: -1;
}

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin: 35px;
}

.bar {
  height: 1.5rem;
  position: relative;
  border: 1px solid #fc8e56;
  border-radius: 1.5rem;
}

.filled {
  position: relative;
  box-sizing: border-box;
  height: 1.5rem;
  border-radius: 1.5rem;
  background: #fc8e56;
}

.label {
  position: absolute;
  top: calc(-1.5rem - 6px);
  right: 0;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.number {
  display: inline;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  background: #fc8e56;
  color: white;

  font-size: 1rem;
}

.tip {
  position: relative;
  top: -5px;
  color: #fc8e56;
}
