.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.3);
}

.window {
  width: 770px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: white;
}

.header {
  height: 82px;
  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 1px solid #E7E7E7;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  
  color: #FF995B;
}

.body {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  /* padding: 40px 150px; */
  padding: 0px 0px;

  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 17px 30px;

  border-top: 1px solid #E7E7E7;
}


.cancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;

  color: #FF995B;

  cursor: default;
  background: none;
  border: none
}


.header > svg {
  margin: 0 10px;
}