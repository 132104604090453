.container {
  display: flex;
  align-items: center;

  margin: 36px 0px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: black;
}

.container > svg {
  margin-right: 10px;

  color: #FF7C1B;
}