.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background: #eeeeee;
  z-index: -1;
}

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin: 35px;
  padding: 40px;
}
/*
.logo {
  margin: 35px;
  padding: 40px;
  background-color: #777777;
  border-radius: 15%;
  box-shadow: 0px 0px 100px #000000 ;
}
*/